import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
    {
        path: '',
        title: 'Tableau de bord',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: [
            {
                path: '/dashboard/default',
                title: 'Adhésions',
                iconType: 'nzIcon',
                icon: 'team',
                iconTheme: 'outline',
                submenu: []
            },
           
        ]
    }
]    