import { Component } from '@angular/core';
import * as firebase from 'firebase';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {


    constructor() {

        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        
        const firebaseConfig = {
            apiKey: "AIzaSyCANV2i4oLag3J1X9ps5WnET0n4CkKq29k",
            authDomain: "glihope-crm.firebaseapp.com",
            databaseURL: "https://glihope-crm-default-rtdb.firebaseio.com",
            projectId: "glihope-crm",
            storageBucket: "glihope-crm.appspot.com",
            messagingSenderId: "307326561396",
            appId: "1:307326561396:web:25d97f69de8b7415c4b521",
            measurementId: "G-12FJCRV3ND"
        };

        firebase.default.initializeApp(firebaseConfig);


       

    }

}
